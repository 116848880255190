import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './modal.module.scss';
import CloseIcon from './closeIcon.svg';
import LightBox from '../../LightBox';

const BoatGroupCardPreviewModal = ({ title, description, image, gallery, isOpen, setIsOpen, list1, list2, additionalInformation }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [activeLightBoxIndex, setActiveLightBoxIndex] = useState(0);
  const _gallery = gallery.map((item) => `${process.env.REACT_APP_API_URL}${item}`);
  const getFirstFourImageFromGallery = _gallery.slice(0, 4);
  const hasLightBox = _gallery.length >= 4;

  const openLightBox = (index) => {
    setIsLightBoxOpen(true);
    setActiveLightBoxIndex(index);
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.overlay)} onClick={() => setIsOpen(false)}></div>
        <div className={cn(style.inner)}>
          <div className={cn(style.header)}>
            <div className={cn(style.title)}>Preview The Boat</div>
            <div className={cn(style.name)} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={cn(style.close)} onClick={() => setIsOpen(false)}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className={cn(style.main)}>
            <div className={cn(style.imagesBlock)}>
              <img className={cn(style.mainImage)} src={image} alt="" onClick={() => openLightBox(0)} />
              <div className={cn(style.list)}>
                {getFirstFourImageFromGallery.map((item, index) => (
                  <div className={cn(style.listItem)} key={item}>
                    <img src={item} alt="" onClick={() => openLightBox(index + 1)} />
                    {index === 3 && hasLightBox && (
                      <div className={cn(style.previewButton)} onClick={() => setIsLightBoxOpen(true)}>
                        Show all photos
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={cn(style.content)}>
              <div className={cn(style.left)}>
                <div className={cn(style.contentBlock)}>
                  <div className={cn(style.contentBlockTitle)}>Description</div>
                  <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                {!!list1 && (
                  <div className={cn(style.contentBlock, style.contentBlockHalf)}>
                    <div className={cn(style.contentBlockTitle)}>What’s Included</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: list1 }} />
                  </div>
                )}
                {!!list2 && (
                  <div className={cn(style.contentBlock, style.contentBlockHalf)}>
                    <div className={cn(style.contentBlockTitle)}>What to Bring</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: list2 }} />
                  </div>
                )}
              </div>
              <div className={cn(style.right)}>
                {!!additionalInformation && (
                  <div className={cn(style.contentBlock)}>
                    <div className={cn(style.contentBlockTitle)}>Important Information</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: additionalInformation }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LightBox isOpen={isLightBoxOpen} setIsOpen={setIsLightBoxOpen} list={[image, ..._gallery]} activeIndex={activeLightBoxIndex} setActiveIndex={setActiveLightBoxIndex} />
    </>
  );
};

BoatGroupCardPreviewModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  gallery: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  list1: PropTypes.string,
  list2: PropTypes.string,
  additionalInformation: PropTypes.string,
};

export default BoatGroupCardPreviewModal;
